// Replace with your access token
const ACCESS_TOKEN = 'EAAJC9w7973cBO970WpQZAZBHbHBpadZBeSNrhTSyf74jUzVTOCJMjqUHY5MzPQLEhiSWjW8AFO8njYLCbmDBpf7eno4fL6xNpfKyRI9nwntu6FmZA3BQ1mQHZAMMycnDafWEkjnhzZBkHl3JKZBbFwGQOyZBVKOGfM7pQAXg4tNAaSs1CAEv5vrj9XcOQhcv5SAS3QZDZD';
const PIXEL_ID = '1300952237645472';

// Function to send events to Conversions API
const sendToConversionsAPI = async (eventName, eventData = {}) => {
  try {
    const response = await fetch(
      `https://graph.facebook.com/v17.0/${PIXEL_ID}/events`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: [{
            event_name: eventName,
            event_time: Math.floor(Date.now() / 1000),
            action_source: 'website',
            ...eventData
          }],
          access_token: ACCESS_TOKEN,
        }),
      }
    );
    const data = await response.json();
    console.log('Conversions API Response:', data);
  } catch (error) {
    console.error('Conversions API Error:', error);
  }
};

// Function to track events both via Pixel and Conversions API
export const trackEvent = (eventName, eventData = null) => {
  // Browser-side tracking (Meta Pixel)
  if (window.fbq) {
    window.fbq('track', eventName, eventData);
  }

  // Server-side tracking (Conversions API)
  sendToConversionsAPI(eventName, eventData);
}; 